import React, { useState, useEffect } from "react";
import Axios from "axios";
import FlDropdown from "../../components/dropdown/Dropdown";
//from packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Button, DropdownItem } from "semantic-ui-react";

//components
import DeleteModal from "./DeleteModal";
import PostCard from "../../components/PostCard/PostCard";

//apis
import { getPostsByIds } from "../../api/api";
import { getFavPosts } from "../../api/api";

//utils
import store from "../../redux/store";

import _ from "lodash";

import { withRouter } from "react-router-dom";

//types
import {
  SET_POST_CARD_DATA_MODAL,
  SET_FAVOURITE_POSTS,
  UPDATE_TAGS,
  CHANGE_TAGS_BAR_FIXED,
  RESET_TAGS,
  SET_AUTH_FAVOURITE_POSTS,
  SET_CAT_LIST,
  SET_TAGS_BY_POST_ORDER,
  SET_TAGS_BY_FAV_POSTS_ORDER,
} from "../../redux/types";
import Loader from "../../components/Loader/Loader";
import { usePrevious } from "../../hooks/usePrevious";
import { Dropdown, Menu } from "semantic-ui-react";
import { isEmpty } from "../../validation/validation";

//metaDecorator
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";

import { toast } from "react-toastify";

import "./favourites.scss";
import GLOBALS from "../../constants";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { AiOutlineClear } from "react-icons/ai";

const sortByOptions = [
  { name: "Newest", code: "Newest", index: 1 },
  { name: "Random", code: "Random", index: 2 },
  { name: "Popularity", code: "Popularity", index: 3 },
  { name: "Oldest", code: "Oldest", index: 4 },
];

let categoriesOption = [{ name: "All Categories", term_id: "All Categories" }];

function Favourites(props) {
  const [loading, setLoading] = useState(true);
  const [clearModalShowing, setShowClearModalShowing] = useState(false);
  const [sortBy, setSortBy] = useState(sortByOptions[0]);
  const [postsArr, setPostsArr] = useState([]);
  const [filteredArry, setFilteredArry] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(
    categoriesOption[0].name
  );
  const [postList, setPostList] = useState([]);
  const [updateState, setUpdateState] = useState(false);
  const [catList, setCatList] = useState([categoriesOption[0].name]);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const activeTag = useSelector((state) => state.world.activeTag);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // localStorage.clear()
    // setFilteredArry([])
    document.getElementsByTagName("body")[0].classList.add("favourites-page");
    document.getElementsByTagName("body")[0].removeAttribute("id");
    if (localStorage.getItem("favouriteSortBy")) {
      setSortBy(JSON.parse(localStorage.getItem("favouriteSortBy")));
    }

    if (localStorage.getItem("favouritesCategory")) {
      setCurrentCategory(
        JSON.parse(localStorage.getItem("favouritesCategory"))
      );
    }

    if (activeTag) {
      setFilteredArry(
        postsArr.filter((post) =>
          post.tags.find((tag) => tag.term_id === activeTag.term_id)
        )
      );
    } else {
      setFilteredArry([]);
    }
    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("favourites-page");
    };
  }, [activeTag]);

  useEffect(() => {
    postListFunc();
  }, [sortBy]);

  useEffect(() => {
    CategoryListFunction();
  });

  useEffect(() => {
    setPostList(postListFunc());
  }, [filteredArry, postsArr]);

  useEffect(() => {
    setPostList(CategoryListFunction());
  }, [filteredArry, postsArr]);

  //this gives previous posts list
  const prevFavPosts = usePrevious(props.favourite.favPosts);

  //get the favposts from the ids
  useEffect(() => {
    (async () => {
      store.dispatch({ type: RESET_TAGS });
      if (!localStorage.getItem("userToken")) {
        const idsArr = (
          JSON.parse(localStorage.getItem("favouritePosts")) || []
        ).map((post, index) => {
          return post.ID;
        });
        if (prevFavPosts !== props.favourite.favPosts) {
          store.dispatch({ type: SET_CAT_LIST, payload: [] });
          store.dispatch({ type: RESET_TAGS });
          await getPostsByIds(`ids=${idsArr.join(",")}`)
            .then((finRes) => {
              // store.dispatch({
              // 	type: SET_FAVOURITE_POSTS,
              // 	payload: finRes.json.posts
              // });
              setPostsArr(finRes.json.posts);
              let newCat = [];
              finRes.json.posts.map((post) => {
                store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
                store.dispatch({
                  type: SET_TAGS_BY_FAV_POSTS_ORDER,
                  payload: post.tags,
                });
                if (!isEmpty(post.categories)) {
                  post.categories.map((category) => {
                    newCat.push(category.name);
                    // categoriesOption = [...categoriesOption, { name: category.name, term_id: category.term_id }];
                  });
                }
              });

              const uniqueCategoryData = _.uniq(newCat);
              store.dispatch({
                type: SET_CAT_LIST,
                payload: [categoriesOption[0].name, ...uniqueCategoryData],
              });
              setLoading(false);
            })
            .catch((err) => {
              console.log(" err ", err);
              setLoading(false);
            });
        }
      } else {
        if (prevFavPosts !== props.favourite.authFavPosts) {
          store.dispatch({ type: SET_CAT_LIST, payload: [] });
          const finRes = await Axios({
            url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/favorite-list`,
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("userToken"),
            },
          });
          if (finRes.status === 200) {
            let ids = [];
            await finRes.data.posts.map((data) => {
              if (!isEmpty(data.categories)) {
                ids.push(data.ID);
              }
            });
            const uniqueIds = _.uniq(ids);

            // get fav posts full details
            await getPostsByIds(`ids=${uniqueIds.join(",")}`)
              .then((finRess) => {
                setPostsArr(finRess.json.posts);
                store.dispatch({
                  type: SET_AUTH_FAVOURITE_POSTS,
                  payload: finRess.json.posts,
                });
                let newCat = [];
                store.dispatch({ type: RESET_TAGS });
                finRess.json.posts.map((post) => {
                  store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
                  store.dispatch({
                    type: SET_TAGS_BY_FAV_POSTS_ORDER,
                    payload: post.tags,
                  });
                  if (!isEmpty(post.categories)) {
                    post.categories.map((category) => {
                      newCat.push(category.name);
                      // categoriesOption = [...categoriesOption, { name: category.name, term_id: category.term_id }];
                    });
                  }
                });

                const uniqueCategoryData = _.uniq(newCat);

                store.dispatch({
                  type: SET_CAT_LIST,
                  payload: [categoriesOption[0].name, ...uniqueCategoryData],
                });

                setLoading(false);
              })
              .catch((err) => {
                console.log(" err ", err);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        }
      }
    })();

    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("favourites-page");
    };
  }, [props.favourite.favPosts, localStorage.getItem("favouritePosts")]);

  //update the tags on load
  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      props.favourite.favPosts.forEach((post) => {
        store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
        // store.dispatch({ type: SET_TAGS_BY_FAV_POSTS_ORDER, payload: post.tags });
      });
    } else {
      const arrayOfCat = !isEmpty(filteredArry) ? filteredArry : postsArr;
      // let filterTag = [];
      // arrayOfCat.forEach((item) => {
      // 	item.categories.forEach((cl) => {
      // 		if (cl.name === currentCategory) {
      // 			filterTag.push(item.tags);
      // 		}
      // 	});
      // });
      // console.log("filterTag", filterTag);
      props.favourite.authFavPosts.forEach((post) => {
        store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
        // store.dispatch({ type: SET_TAGS_BY_FAV_POSTS_ORDER, payload: post.tags });
      });
    }
  }, [props.favourite.favPosts, props.favourite.authFavPosts]);

  const _openPostModal = (id) => {
    store.dispatch({
      type: SET_POST_CARD_DATA_MODAL,
      payload: { postCardModal: true, activePostCardModal: id },
    });
  };

  //Clear favourites list
  const _clearFavourites = () => {
    if (localStorage.getItem("userToken")) {
      return Axios({
        url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/clear-favorite`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
      }).then(
        (res) => {
          notify(res.data.message);
          return Axios({
            url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/favorite-list`,
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("userToken"),
            },
          }).then(
            (res) => {
              setShowClearModalShowing(false);
              setPostsArr(res.data.posts);
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      store.dispatch({
        type: SET_FAVOURITE_POSTS,
        payload: [],
      });
      notify("Successfully cleared all favorites");
      setShowClearModalShowing(false);
    }
  };

  const notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  //Set favourites sort type
  const _handleClick = (name) => {
    setSortBy({ name: name, code: name });
    localStorage.setItem(
      "favouriteSortBy",
      JSON.stringify({ name: name, code: name })
    );
  };

  //Set category type
  const _handelCategoriesClick = (name) => {
    setCurrentCategory(name);
    let filterFavArr = postsArr;
    if (activeTag) {
      filterFavArr = postsArr.filter((post) =>
        post.tags.find((tag) => tag.term_id === activeTag.term_id)
      );
    }

    if (name) {
      filterFavArr = !isEmpty(filterFavArr)
        ? filterFavArr.filter((data, index) => {
            return data.categories.find((category) => category.name === name);
          })
        : postsArr.filter((data, index) => {
            return data.categories.find((category) => category.name === name);
          });
    }

    if (name !== "All Categories") {
      store.dispatch({ type: RESET_TAGS });
      filterFavArr &&
        filterFavArr.forEach((post) => {
          store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
          // store.dispatch({ type: SET_TAGS_BY_FAV_POSTS_ORDER, payload: post.tags });
        });
    } else {
      if (localStorage.getItem("userToken")) {
        props.favourite.authFavPosts.forEach((post) => {
          store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
          // store.dispatch({ type: SET_TAGS_BY_FAV_POSTS_ORDER, payload: post.tags });
        });
      } else {
        props.favourite.favPosts.forEach((post) => {
          store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
          // store.dispatch({ type: SET_TAGS_BY_FAV_POSTS_ORDER, payload: post.tags });
        });
      }
    }
    localStorage.setItem("favouritesCategory", JSON.stringify(name));
  };

  //Display favourites clear modal
  const _triggerClearFavouritePosts = () => {
    setShowClearModalShowing(true);
  };

  //Set post list based in sort type
  const postListFunc = () => {
    return (!isEmpty(filteredArry) ? filteredArry : postsArr)
      .sort((a, b) => {
        switch (sortBy.code) {
          case "Popularity":
            return b.up_vote_count - a.up_vote_count;
          case "Random":
            return Math.random() - 0.5;
          case "Newest":
            return new Date(b.post_date) - new Date(a.post_date);
          case "Oldest":
            return new Date(a.post_date) - new Date(b.post_date);
          default:
            return b.up_vote_count - a.up_vote_count;
        }
      })
      .map((post, index) => {
        return (
          <PostCard
            postsArr={!isEmpty(filteredArry) ? filteredArry : postsArr}
            onOpen={() => _openPostModal(post.ID)}
            key={index}
            upvotedMe={(store.getState().world.upvotedPosts || []).find(
              (postt) => postt.ID === post.ID
            )}
            postData={post}
            postId={index}
            testProp="first"
          />
        );
      });
  };

  const CategoryListFunction = () => {
    if (currentCategory) {
      return (!isEmpty(filteredArry) ? filteredArry : postsArr)
        .filter((item) => {
          if (currentCategory !== "All Categories") {
            const filterarr = item.categories.find(
              (category) => category.name === currentCategory
            );
            return filterarr;
          } else {
            return item.categories;
          }
        })
        .map((post, index) => {
          return (
            <PostCard
              postsArr={!isEmpty(filteredArry) ? filteredArry : postsArr}
              onOpen={() => _openPostModal(post.ID)}
              key={index}
              upvotedMe={(store.getState().world.upvotedPosts || []).find(
                (postt) => postt.ID === post.ID
              )}
              postData={post}
              postId={index}
              testProp="second"
            />
          );
        });
    } else {
      return (!isEmpty(filteredArry) ? filteredArry : postsArr).map(
        (data, index) => {
          return (
            <PostCard
              postsArr={!isEmpty(filteredArry) ? filteredArry : postsArr}
              onOpen={() => _openPostModal(data.ID)}
              key={index}
              upvotedMe={(store.getState().world.upvotedPosts || []).find(
                (postt) => postt.ID === data.ID
              )}
              postData={data}
              postId={index}
              testProp="third"
            />
          );
        }
      );
    }
  };

  useEffect(() => {
    if (currentCategory) {
      return setPostList(
        (!isEmpty(filteredArry) ? filteredArry : postsArr)
          .filter((item) => {
            if (currentCategory !== "All Categories") {
              return item.categories.find(
                (category) => category.name === currentCategory
              );
            } else {
              return item.categories;
            }
          })
          .map((post, index) => {
            return (
              <PostCard
                postsArr={!isEmpty(filteredArry) ? filteredArry : postsArr}
                onOpen={() => _openPostModal(post.ID)}
                key={index}
                upvotedMe={(store.getState().world.upvotedPosts || []).find(
                  (postt) => postt.ID === post.ID
                )}
                postData={post}
                testProp="fourth"
              />
            );
          })
      );
    }
  }, [sortBy, currentCategory]);

  useEffect(() => {
    return setPostList(
      (!isEmpty(filteredArry) ? filteredArry : postsArr)
        .filter((item) => {
          return (
            (item.post_title &&
              item.post_title.toLowerCase() &&
              item.post_title.toLowerCase().indexOf(searchText.toLowerCase()) >=
                0) ||
            (item.user_submit_url &&
              item.user_submit_url.toLowerCase() &&
              item.user_submit_url
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) >= 0)
          );
        })
        .map((post, index) => {
          return (
            <PostCard
              postsArr={!isEmpty(filteredArry) ? filteredArry : postsArr}
              onOpen={() => _openPostModal(post.ID)}
              key={index}
              upvotedMe={(store.getState().world.upvotedPosts || []).find(
                (postt) => postt.ID === post.ID
              )}
              postData={post}
              testProp="fourth"
            />
          );
        })
    );
  }, [searchText]);

  let isMobile = window.innerWidth <= 992;

  const cookieConsent = JSON.parse(localStorage.getItem("cookie-consent"));

  if (!cookieConsent || !cookieConsent.necessary) {
    notify("Accept necessary cookies to access favorites page");
    localStorage.clear("disclaimerResponded");
    props.history.push("/");
  }

  const _search = (e) => {
    // handle search
    setSearchText(e.target.value);
  };

  return (
    <div
      className="p-grid p-fluid dashboard page-favourites"
      style={{
        marginTop: store.getState().settings.filterBar ? "0px" : "00px",
      }}
    >
      <MetaDecorator metaDataPerRoute={metaDecoratorData.favorites} />

      <div className="p-col-12 p-lg-12">
        <div
          className={
            window.innerWidth < 600
              ? "favourite-titles-mobile"
              : "favourite-titles"
          }
          style={{ zIndex: -2 }}
        >
          {props.favouriteTitle || localStorage.getItem("userToken") ? (
            <div
              className={`p-col-12 p-md-12 ${
                props.settings.darkMode ? "page-header" : "page-header-light"
              }`}
            >
              <i
                class="glyph-icon flaticon-star active"
                style={{ color: "grey" }}
              ></i>
              <b style={{ color: "grey" }}>Favorite Links</b>
            </div>
          ) : (
            <div
              className={`${
                props.settings.darkMode ? "page-header" : "page-header-light"
              }`}
              style={{
                width: "100%",
                display: "flex",
                alignItems: isMobile ? "center" : "flex-start",
              }}
            >
              <i
                class="glyph-icon flaticon-star active"
                style={{ color: "grey" }}
              ></i>
              <span style={{ fontStyle: "italic", color: "grey" }}>
                Login to view saved favorites across devices
              </span>
              {/* <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: darkMode
                    ? "rgba(255,255,255,0.1)"
                    : "rgba(0,0,0,0.1)",
                  margin: "10px 0px 10px 0px",
                }}
              ></div> */}
            </div>
          )}
        </div>
        <div
          className="p-col-12 p-md-12"
          id={`fav-container`}
          style={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
            zIndex: "auto",
          }}
        >
          <div
            className={`fav-header-action-container__search${
              darkMode ? "" : "-light"
            } ${
              !isEmpty(searchText)
                ? darkMode
                  ? "search-active"
                  : "search-active-light"
                : ""
            }`}
          >
            <label for="fav-search" className={`fav-search-link`}>
              <i class="glyph-icon flaticon-loupe"></i>
            </label>
            <input
              name="fav-search"
              id="fav-search"
              className="fav-search-input"
              placeholder="Search Favorites"
              onChange={_search}
            />
          </div>
          <span
            className="fav-dropdown"
            id={darkMode ? "menu-span" : "menu-span-light"}
            style={{
              minWidth: window.innerWidth < 600 ? "100px" : "140px",
              // marginRight: "5px"
            }}
          >
            <Menu className="cat-parent-menu">
              <Dropdown
                text={
                  currentCategory
                    ? window.innerWidth < 600
                      ? `${currentCategory.slice(0, 10)}..`
                      : currentCategory
                    : "Categories"
                }
                fluid
                pointing
                className="link item favourites-sortby-dropdown"
              >
                <Dropdown.Menu
                  style={{ minWidth: "200px", backgroundColor: "red" }}
                >
                  {store
                    .getState()
                    .favourite.catList.reverse()
                    .map((data, index) => {
                      return (
                        <DropdownItem
                          className={
                            props.settings.darkMode ? "dark-item" : "item"
                          }
                          onClick={() => {
                            _handelCategoriesClick(data);
                          }}
                          key={index}
                        >
                          {data}
                        </DropdownItem>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </span>
          <span
            className="fav-dropdown-2"
            style={{
              width: window.innerWidth > 600 ? "140px" : "120px",
              marginRight: "3px",
              overflow: "hidden",
            }}
          >
            <CustomDropdown
              handleOnClick={_handleClick}
              sortByValue={sortBy.code ? sortBy.code : "Sort By"}
              dropdownName="Sort By"
              dropdownOptions={sortByOptions}
            />
          </span>
          &nbsp;
          <Button
            onClick={_triggerClearFavouritePosts}
            size="tiny"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            color="red"
          >
            <AiOutlineClear style={{ fontSize: "18px", marginRight: "5px" }} />
            {window.innerWidth > 600 && (
              <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                Clear All
              </span>
            )}
          </Button>
        </div>
      </div>
      {postList}
      <div style={{ display: "block" }} className="loading-more-wrapper">
        {loading && <Loader loadingMore={true} />}
      </div>
      {localStorage.getItem("userToken") ? (
        postsArr.length < 1 ? (
          <div
            style={{ textAlign: "center", zIndex: -1 }}
            className="p-col-12 p-text-center card summary"
          >
            No Links
          </div>
        ) : null
      ) : localStorage.getItem("favouritePosts") &&
        localStorage.getItem("favouritePosts").length > 1 ? null : (
        <div
          style={{ textAlign: "center", zIndex: -1 }}
          className="p-col-12 p-text-center card summary"
        >
          No Links
        </div>
      )}
      <DeleteModal
        open={clearModalShowing}
        onClose={() => setShowClearModalShowing(false)}
        _clearFavourites={_clearFavourites}
      />
    </div>
  );
}

Favourites.propsTypes = {
  favourite: PropTypes.object.isRequired,
  activeTag: PropTypes.object,
};

const mapStateToProps = (state) => ({
  favourite: state.favourite,
  activeTag: state.world.activeTag,
  favPostTags: state.world.tagsByFavPosts,
  settings: state.settings,
});

export default connect(mapStateToProps, {})(withRouter(Favourites));
