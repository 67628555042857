import React, { useEffect } from "react";
import { Button, Input, Modal, Icon, ModalContent } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import AddPostModal from "../AddPost/AddPostModal";
import { getSubmittedPost } from "../../api/api";
import { toast } from "react-toastify";

function SubmissionModal(props) {
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [loading, setLoading] = React.useState(true);
  const [initialFormData, setInitialFormData] = React.useState({
    title: "",
    url: "",
    selectedParentLinks: [],
    category: "",
    tags: [],
    status: "",
  });
  const [inTags, setInTags] = React.useState([]);
  const notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  const _getSubmittedPost = (query, signal) => {
    setLoading(true);
    getSubmittedPost(query, signal)
      .then((res) => {
        if (res.response.ok) {
          setLoading(false);
          let inTagss = res.json.tags
            ? res.json.tags.map((tagg) => {
                return tagg.name;
              })
            : [];
          setInTags(inTagss);
          let data = {
            title: res.json.post_title ? res.json.post_title : "",
            url: res.json.user_submit_url ? res.json.user_submit_url : "",
            selectedParentLinks: res.json.link_types
              ? [
                  ...res.json.link_types.map((lt) => {
                    return lt.term_id;
                  }),
                ]
              : [],
            category:
              res.json.categories &&
              res.json.categories[0] &&
              res.json.categories[0].term_id
                ? res.json.categories[0].term_id
                : "",
            tags: res.json.tags
              ? res.json.tags.map((tagg) => {
                  return tagg.name;
                })
              : [],
            status: res.json.post_status
              ? res.json.post_status.toString().toUpperCase()
              : "-",
          };
          setInitialFormData(data);
        } else {
          notify("Failed to get data!");
          props.onClose();
        }
      })
      .catch((err) => {
        // console.log(err);
        notify("Failed to get data!");
        props.onClose();
      });
  };

  useEffect(() => {
    if (props.open) {
      const controller = new AbortController();
      _getSubmittedPost(`?id=${props.linkid}`, controller.signal);
      return () => {
        controller.abort();
      };
    }
  }, [props.open]);

  const _onSubmitCallback = () => {
    // on submit callback
    props.refetchData();
  };

  return (
    <Modal
      onClose={() => {
        props.onClose();
        setInitialFormData({
          title: "",
          url: "",
          selectedParentLinks: [],
          category: "",
          tags: [],
        });
      }}
      onOpen={props.onClose}
      open={props.open}
      className={
        darkMode
          ? "subscribe-notification-modal-dark add-post-modal"
          : "subscribe-notification-modal-light add-post-modal"
      }
      style={{ backgroundColor: darkMode ? "#3c3c3c" : "#EDF0F5" }}
    >
      <ModalContent
        style={{ backgroundColor: darkMode ? "#3c3c3c" : "#EDF0F5" }}
      >
        <div className="subs-close">
          <IoCloseSharp
            onClick={() => {
              props.onClose();
              setInitialFormData({
                title: "",
                url: "",
                selectedParentLinks: [],
                category: "",
                tags: [],
              });
            }}
          />
        </div>
        <div className="update-modal-content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "20px",
            }}
          >
            <span
              style={{
                fontWeight: 900,
                fontSize: "15px",
                color: darkMode ? "white" : "black",
              }}
            >
              STATUS :{" "}
            </span>
            <span
              style={{
                marginLeft: "5px",
                fontSize: "15px",
                opacity: 0.8,
                color: darkMode ? "white" : "black",
              }}
            >
              {initialFormData.status}
            </span>
          </div>
          <br />
          <AddPostModal
            onClose={() => {
              props.onClose();
              setInitialFormData({
                title: "",
                url: "",
                selectedParentLinks: [],
                category: "",
                tags: [],
              });
            }}
            loading={loading}
            initialTags={inTags}
            id={props.linkid}
            initialData={initialFormData}
            onSubmitCallback={_onSubmitCallback}
          />
        </div>
      </ModalContent>
    </Modal>
  );
}

export default SubmissionModal;
