import React from "react";
import { Icon } from "semantic-ui-react";
import chain from "../../assets/img/chain.svg";

function LinksModal(props) {
  return (
    <div
      className={props.darkMode ? "modal-container-dark" : "modal-container"}
    >
      <div
        className="backdrop"
        onClick={() => props.setLinksModal(false)}
      ></div>
      <div
        className={props.darkMode ? "links-container" : "links-container-light"}
      >
        <div
          className={
            props.darkMode
              ? "links-container__header"
              : "links-container-light__header"
          }
        >
          <span>
            <img src={chain} />
            Links.to/Pikilinks
          </span>
          <Icon
            name="close"
            style={{
              cursor: "pointer",
              fontSize: "18px",
              color: props.darkMode ? "gray" : "gray",
            }}
            onClick={() => props.setLinksModal(false)}
          />
        </div>
        <iframe src={props.link} title="Links.to/Pikilinks"></iframe>
      </div>
    </div>
  );
}

export default LinksModal;
