import React, { useEffect } from "react";

//from packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Icon, Label } from "semantic-ui-react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Dropdown } from "semantic-ui-react";
//utils
import store from "../../redux/store";
import { isEmpty } from "../../validation/validation";
import { MdTune } from "react-icons/md";
//types
import { SET_ACTIVE_TAG } from "../../redux/types";
import { AiOutlineSortAscending } from "react-icons/ai";
import { FcAlphabeticalSortingZa } from "react-icons/fc";
import {
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
  FaSortAmountDownAlt,
} from "react-icons/fa";
import { SET_POST_CARD_DATA_MODAL } from "../../redux/types";

const excludedRoutes = [
  "/terms",
  "/privacy",
  "/send-feedback",
  "/add-post",
  "/lists",
  "/subscription",
  "/activity",
  "/account",
  "/about",
  "/submit-link",
  "/info",
  "/faq",
  "/platforms",
  "/how-to",
  "/disclaimer",
  "/cookies",
  "/eula",
  "/use",
];

const volatileRoutes = ["/", "/post", "/icon-library", "/link"];

function TagsBar(props) {
  const [activeSort, setActiveSort] = React.useState("postOrder");

  //reset tags on changing of path name
  useEffect(() => {
    store.dispatch({ type: SET_ACTIVE_TAG, payload: "" });
  }, [props.location.pathname]);

  const _clickAllTags = () => {
    store.dispatch({ type: SET_ACTIVE_TAG, payload: "" });
  };

  const _clickTag = (tag) => {
    let newPath = props.history.location.pathname.split("/");
    let fin = "/" + newPath[1];
    if (volatileRoutes.includes(fin)) {
      props.history.push("/tags/" + tag.slug);
    } else {
      //set the active tag in store
      if (props.postsModal) {
        props.history.push("/tags/" + tag.slug);
      } else {
        store.dispatch({ type: SET_ACTIVE_TAG, payload: tag });
      }
    }
  };
  if (excludedRoutes.includes(props.location.pathname)) {
    return null;
  }

  let newPath2 = props.history.location.pathname.split("/");
  let fin2 = "/" + newPath2[1];

  const _byPostOrder = () => {
    let newPath = props.history.location.pathname.split("/");
    let fin = "/" + newPath[1];

    if (fin === "/favorites") {
      store.dispatch({
        type: "UPDATE_WHOLE_TAGS",
        payload: [...props.tagsByFavPosts],
      });
    } else if (fin === "/category") {
      store.dispatch({
        type: "UPDATE_WHOLE_TAGS",
        payload: [...props.tagsByCatPosts],
      });
    } else if (fin === "/icon-library") {
      store.dispatch({
        type: "UPDATE_WHOLE_TAGS",
        payload: [...props.tagsByIconPosts],
      });
    } else {
      store.dispatch({
        type: "UPDATE_WHOLE_TAGS",
        payload: [...props.tagsByPostsOrder],
      });
    }
    setActiveSort("postOrder");
  };

  const _byAscending = () => {
    const ascendingTags = [...props.tags].sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (b.name > a.name) {
        return -1;
      }
      return 0;
    });
    store.dispatch({ type: "UPDATE_WHOLE_TAGS", payload: [...ascendingTags] });
    setActiveSort("ascending");
  };

  const _byDescending = () => {
    const ascendingTags = [...props.tags].sort(function (a, b) {
      if (a.name > b.name) {
        return -1;
      }
      if (b.name > a.name) {
        return 1;
      }
      return 0;
    });
    store.dispatch({ type: "UPDATE_WHOLE_TAGS", payload: [...ascendingTags] });
    setActiveSort("desending");
  };

  const updatedTags = [...props.tags];
  if (!isEmpty(props.activeTag)) {
    updatedTags.unshift({
      term_id: "allTags",
      name: "All Tags",
    });
  }

  function moveElement(array, fromIndex, toIndex) {
    const element = array.splice(fromIndex, 1)[0];
    array.splice(toIndex, 0, element);
    return array;
  }

  const isMobile = window.innerWidth < 450;

  let circularTags = true;
  if (
    props.history.location.pathname === "/" ||
    props.history.location.pathname === "/icon-library" ||
    props.history.location.pathname === "/link"
  ) {
    circularTags = false;
  }
  const isTagsBarFixed = props.location.pathname === "";
  const isTagsBarOut =
    props.location.pathname === "/" ||
    props.location.pathname === "/submit-link";

  const darkMode = store.getState().settings.darkMode;

  let RearrangedTags = [...updatedTags];

  if (props.activeTag && props.activeTag.term_id) {
    let fromIndex =
      updatedTags &&
      updatedTags.findIndex((ut) => {
        return ut.term_id === props.activeTag.term_id;
      });
    let toIndex = 1;
    RearrangedTags = moveElement([...updatedTags], fromIndex, toIndex);
  }

  return (
    <>
      <div
        className={`tags-bar ${
          props.postsModal ? "remove-padding fit-modal" : ""
        } ${props.darkMode ? "tags-dark-mode" : "tags-light-mode"} ${
          isTagsBarFixed ? "fixed" : ""
        } ${
          isTagsBarFixed && window.innerWidth > 1024
            ? props.iconMode
              ? "DesktopTagsBar TagsBarOnIconMode"
              : "DesktopTagsBar"
            : window.innerWidth > 700
            ? "MobileTagsBar"
            : "MobileTagsBarWithPadding"
        }`}
        id="tags-dropdown-parent"
        style={{
          width: props.location.pathname === "/icon-library" ? "100%" : "100%",
        }}
      >
        {props.postsModal ? (
          <ScrollMenu
            style={{ width: "100%", overflow: "hidden" }}
            arrowLeft={
              !isMobile ? (
                <i className={`glyph-icon flaticon-left-arrow-1`}></i>
              ) : undefined
            }
            arrowRight={
              !isMobile ? (
                <i className={`glyph-icon flaticon-right-arrow-1`}></i>
              ) : undefined
            }
            alignCenter={false}
            dragging={true}
            data={props.modalTags.map((tag, index) => {
              if (index === 0 && props.activeTag) {
                return (
                  <Label
                    key={index}
                    className="custom-label"
                    onClick={_clickAllTags}
                  >
                    <span className="tags-name">
                      <Icon name="tag" />
                      {tag.name}
                    </span>
                  </Label>
                );
              }

              return (
                <Label
                  key={index}
                  className="custom-label"
                  onClick={() => {
                    _clickTag(tag);
                    if (props.postsModal) {
                      store.dispatch({
                        type: SET_POST_CARD_DATA_MODAL,
                        payload: {
                          postCardModal: false,
                          activePostCardModal: null,
                        },
                      });
                    }
                  }}
                  // circular={circularTags}
                  color={props.activeTag === tag ? "grey" : null}
                  style={{
                    backgroundColor: darkMode
                      ? "hsla(0,0%,50.2%,.1)"
                      : "hsla(0,0%,100%,.35)",
                  }}
                >
                  <span
                    className={
                      props.activeTag === tag ? "tag-span" : "tag-span"
                    }
                  >
                    {tag.name}
                  </span>
                </Label>
              );
            })}
            hideArrows={true}
            hideSingleArrow={true}
          />
        ) : (
          <ScrollMenu
            arrowLeft={
              !isMobile ? (
                <i className={`glyph-icon flaticon-left-arrow-1`}></i>
              ) : undefined
            }
            arrowRight={
              !isMobile ? (
                <i className={`glyph-icon flaticon-right-arrow-1`}></i>
              ) : undefined
            }
            alignCenter={false}
            dragging={true}
            data={(props.activeTag
              ? [...RearrangedTags]
              : [...updatedTags]
            ).map((tag, index) => {
              if (index === 0 && props.activeTag) {
                return (
                  <Label
                    key={index}
                    className="custom-label"
                    onClick={_clickAllTags}
                    circular
                  >
                    <span className="tags-name">
                      <Icon name="tag" />
                      {tag.name}
                    </span>
                  </Label>
                );
              }

              return (
                <Label
                  key={index}
                  className="custom-label"
                  onClick={() => {
                    _clickTag(tag);
                    if (props.postsModal) {
                      store.dispatch({
                        type: SET_POST_CARD_DATA_MODAL,
                        payload: {
                          postCardModal: false,
                          activePostCardModal: null,
                        },
                      });
                    }
                  }}
                  circular={circularTags}
                  style={{ marginTop: darkMode ? "1px" : "0px" }}
                  color={props.activeTag === tag ? "grey" : null}
                >
                  <span
                    className={
                      props.activeTag === tag ? "tag-span" : "tag-span"
                    }
                  >
                    {tag.name}
                  </span>
                </Label>
              );
            })}
            hideArrows={true}
            hideSingleArrow={true}
          />
        )}
        {/* <ScrollMenu
					arrowLeft={!isMobile ? <i className={`glyph-icon flaticon-left-arrow-1`}></i> : undefined}
					arrowRight={!isMobile ? <i className={`glyph-icon flaticon-right-arrow-1`}></i> : undefined}
					alignCenter={false}
					dragging={true}
					data={updatedTags.map((tag, index) => {
						if (index === 0 && props.activeTag) {
							return (
								<Label key={index} className="custom-label" onClick={_clickAllTags} circular>
									<span className="tags-name">
										<Icon name="tag" />
										{tag.name}
									</span>
								</Label>
							);
						}

						return (
							<Label key={index} className="custom-label" onClick={() => _clickTag(tag)} circular={circularTags} color={props.activeTag === tag ? "grey" : null}>
								<span className={props.activeTag === tag ? "tag-span" : "tag-span"}>{tag.name}</span>
							</Label>
						);
					})}
					hideArrows={true}
					hideSingleArrow={true}
				/> */}
      </div>
      {/* {props.postsModal ? null : props.location.pathname !== "/icon-library" ? (
				<div className={`tags-bar fixed`} id="tags-dropdown" style={{ maxWidth: "5%", height: "50px", marginLeft: "auto", right: "10px", marginTop: isTagsBarOut ? "0px" : "-50px", zIndex: 100 }}>
					<Dropdown
						// defaultValue="Important"
						style={{
							// borderStyle: "solid",
							width: "100%",
							borderRadius: "0px",
							backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
							color: props.darkMode ? "#808080" : "#3C3C3C",
							height: "100%",
							marginLeft: isMobile ? "-30px" : "-0px",
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start"
						}}
						icon={
							activeSort === "postOrder" ? (
								<FaSortAmountDownAlt style={{ fontSize: "18px", color: props.darkMode ? "#808080" : "#8A8A8A", marginLeft: isMobile ? "-45px" : "-25px" }} />
							) : activeSort === "ascending" ? (
								<FaSortAlphaDown style={{ fontSize: "18px", color: props.darkMode ? "#808080" : "#8A8A8A", marginLeft: isMobile ? "-45px" : "-25px" }} />
							) : (
								<FaSortAlphaUpAlt style={{ fontSize: "18px", color: props.darkMode ? "#808080" : "#8A8A8A", marginLeft: isMobile ? "-45px" : "-25px" }} />
							)
						}
						text={null}
						direction="left"
						floating
						labeled
						button
						className="icon"
					>
						<Dropdown.Menu>
							<Dropdown.Header icon={null} content={<span style={{ color: props.darkMode ? "rgb(150, 149, 149)" : "#3C3C3C" }}>Filter by tags</span>} />
							<Dropdown.Divider />
							{props.history.location.pathname !== "/link" ? (
								<Dropdown.Item
									onClick={_byPostOrder}
									text={
										<span style={{ color: props.darkMode ? "rgb(150, 149, 149)" : "#3C3C3C", padding: "2px", display: "flex", alignItems: "center", justifyContent: "center" }}>
											<FaSortAmountDownAlt style={{ fontSize: "15px", marginRight: "10px" }} /> Tags by Post Order
										</span>
									}
								/>
							) : null}

							<Dropdown.Item
								onClick={_byAscending}
								text={
									<span style={{ color: props.darkMode ? "rgb(150, 149, 149)" : "#3C3C3C", padding: "2px", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<FaSortAlphaDown style={{ fontSize: "15px", marginRight: "10px" }} /> Ascend Alphabetical
									</span>
								}
							/>
							<Dropdown.Item
								onClick={_byDescending}
								text={
									<span style={{ color: props.darkMode ? "rgb(150, 149, 149)" : "#3C3C3C", padding: "2px", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<FaSortAlphaUpAlt style={{ fontSize: "15px", marginRight: "10px" }} /> Decend Alphabetical
									</span>
								}
							/>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			) : null} */}
    </>
  );
}

TagsBar.propTypes = {
  tags: PropTypes.array.isRequired,
  activeTag: PropTypes.any.isRequired,
  tagsByPostsOrder: PropTypes.array.isRequired,
  tagsByFavPosts: PropTypes.array.isRequired,
  tagsByCatPosts: PropTypes.array.isRequired,
  tagsByIconPosts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  tags: state.tags,
  activeTag: state.world.activeTag,
  tagsByPostsOrder: state.world.tagsByPostsOrder,
  tagsByFavPosts: state.world.tagsByFavPosts,
  tagsByCatPosts: state.world.tagsByCatPosts,
  tagsByIconPosts: state.world.tagsByIconPosts,
  postsModalStatus: state.world.postCardModal,
});

export default withRouter(connect(mapStateToProps)(TagsBar));
