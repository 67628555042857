import React, { useRef, useState, useEffect } from "react";

//from packages
import store from "../../redux/store";
import { toast } from "react-toastify";
import { Input, Label, Dropdown, Icon } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import ReCAPTCHAV2 from "react-google-recaptcha";
import Multiselect from "multiselect-react-dropdown";
import { Form, Message, Modal } from "semantic-ui-react";
import {
  checkUrlValidation,
  getIcons,
  getPostsByTag,
  getPointsData,
  verifyPublishedLinks,
  updateSubmittedPost,
} from "../../api/api";
//components
import TagsInput from "./TagsInput";
import LinkTypeInput from "./LinkTypeInput";
//apis
import { addPost, getLinkTypeData } from "../../api/api";
import { BiAddToQueue } from "react-icons/bi";

//utils
import { validateUpdatePostInputs } from "../../validation/validation";

//MetaDecorator
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";
import isEmpty from "../../utils/isEmpty";
import { SET_IS_DROP_ON } from "../../redux/types";
import { prev } from "dom7";
import TextareaAutosize from "react-textarea-autosize";

const ErrorBody = (props) => {
  if (!props.children) return null;
  return (
    <Label basic color="red" pointing>
      {props.children}
    </Label>
  );
};

// https://dev.pikilinks.com/unsubscribe?email=baralanjal7@gmail.com&unsubscribed_id=c5532416be82a3173ed88ceca8629233

const dataModel = {
  email: "",
  title: "",
  url: "",
  linkType: "",
  category: "",
  tags: [],
  recaptchaVerified: false,
  "g-recaptcha-response": "",
};

const MultiSelectOptions = [
  {
    cat: "Group 1",
    key: "Option 1",
  },
  {
    cat: "Group 1",
    key: "Option 2",
  },
  {
    cat: "Group 1",
    key: "Option 3",
  },
  {
    cat: "Group 2",
    key: "Option 4",
  },
  {
    cat: "Group 2",
    key: "Option 5",
  },
  {
    cat: "Group 2",
    key: "Option 6",
  },
  {
    cat: "Group 2",
    key: "Option 7",
  },
];

function AddPostModal(props) {
  const captchaRef = useRef(null);
  const dropRef = useRef(null);
  const tagsRef = useRef(null);
  const [linkTypeOptions, setLinkTypeOptions] = useState([]);
  const [selectedParentLinks, setSelectedParentLinks] = useState([]);
  const [selectedChildLinks, setSelectedChildLinks] = useState([]);
  const [addPostData, setAddPostData] = useState({ ...dataModel });
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [linkType, setLinkType] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [dm, setDm] = useState("dark");
  const [lastTagImage, setLastTagImage] = useState("");
  const [tagImageLoader, setTagImageLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAutoCompleteOn, setIsAutoCompleteOn] = useState(false);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [pointsData, setPointsData] = useState({
    submitted: "0",
    published: "0",
    total: "0",
  });
  const [inData, setInData] = useState({});
  const options = store
    .getState()
    .categories.categories.filter((data, index) => data.term_id !== 884886)
    .map((cat, index) => {
      if (cat.term_id) {
        return {
          key: index,
          text: cat.name,
          value: cat.term_id,
          image: { avatar: true, src: cat.icon ? cat.icon : "" },
        };
      }
    });

  useEffect(() => {
    if (props.initialData.tags) {
      let lastTagLen = props.initialData.tags.length - 1;
      let lastTag = props.initialData.tags[lastTagLen];
      getTagIcon_(lastTag);
    }
    if (props.initialData) {
      if (props.initialData.title) {
        setTitle(props.initialData.title);
      }
      if (props.initialData.url) {
        setUrl(props.initialData.url);
      }
      if (props.initialData.selectedParentLinks) {
        setSelectedParentLinks(
          props.initialData.selectedParentLinks
            ? props.initialData.selectedParentLinks
            : []
        );
      }
      if (props.initialData.category) {
        setCategory(props.initialData.category);
      }
      if (props.initialData.tags) {
        setTags(props.initialData.tags);
      }
    }
  }, [props.initialData]);

  let isInitialDataChanged = false;
  if (
    props.initialData.title !== title ||
    props.initialData.url !== url ||
    props.initialData.category !== category ||
    JSON.stringify(props.initialData.selectedParentLinks) !==
      JSON.stringify([
        ...new Set([...selectedParentLinks, ...selectedChildLinks]),
      ]) ||
    !_.isEqual(props.initialTags, tags)
  ) {
    isInitialDataChanged = true;
  }

  const _getPointsData = (query, signal) => {
    getPointsData(query, signal)
      .then((res) => {
        if (res.response.ok) {
          setPointsData({
            submitted:
              res.json && res.json.total_submitted_link
                ? res.json.total_submitted_link
                : "0",
            published:
              res.json && res.json.total_published_link
                ? res.json.total_published_link
                : "0",
            total:
              res.json && res.json.total_earned ? res.json.total_earned : "0",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    if (localStorage.getItem("userToken")) {
      _getPointsData("", controller.signal);
    } else {
      // set data for unauth user points
      let submittedLinks = localStorage.getItem("submittedLinks");
      let submittedLinkCount = "0";
      if (submittedLinks) {
        submittedLinkCount = JSON.parse(submittedLinks).length || 0;
      }
      // Verify Published Links
      let publishedLinkCount = "0";
      let filterQuery = "";
      if (submittedLinks) {
        JSON.parse(submittedLinks).forEach((elem, ind) => {
          if (ind === 0) {
            filterQuery += `?id=${elem.ID}`;
          } else {
            filterQuery += `,${elem.ID}`;
          }
        });
      }

      verifyPublishedLinks(filterQuery, controller.signal)
        .then((res) => {
          if (res.response.ok) {
            publishedLinkCount = res.json.total_published_link;
          }
        })
        .catch((err) => {
          // console.log(err);
        });
      setPointsData({
        total: "0",
        published: publishedLinkCount,
        submitted: submittedLinkCount,
      });
    }
    return () => {
      controller.abort();
    };
  }, [localStorage.getItem("userToken")]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      let checkboxes = document.getElementById("checkboxes");

      if (
        isMenuOpen &&
        dropRef.current &&
        !dropRef.current.contains(e.target)
      ) {
        checkboxes.style.display = "none";
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      let autoCompleteTags = document.getElementById("autoComplete");

      if (
        isAutoCompleteOn &&
        tagsRef.current &&
        !tagsRef.current.contains(e.target)
      ) {
        autoCompleteTags.style.display = "none";
        setIsAutoCompleteOn(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isAutoCompleteOn]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("add-post-page");
    document.getElementsByTagName("body")[0].removeAttribute("id");

    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("add-post-page");
    };
  }, []);

  const getTagIcon_ = (tag) => {
    let filterQuery = `tag=${tag}`;
    setTagImageLoader(true);
    getPostsByTag(filterQuery)
      .then((res) => {
        setTagImageLoader(false);
        if (res.response.ok) {
          if (res.json.tag && res.json.tag.url) {
            setLastTagImage(res.json.tag.url);
          } else {
            setLastTagImage("");
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const isIphone5 = useMediaQuery({ query: "(max-width: 320px)" });

  useEffect(() => {
    const iconBox = document.createElement("div");
    iconBox.innerHTML =
      '<i aria-hidden="true" class="list icon add-category-icon"></i>';

    const categoryDiv =
      document.getElementsByClassName("addpost-category") &&
      document.getElementsByClassName("addpost-category")[0] &&
      document.getElementsByClassName("addpost-category")[0].childNodes &&
      document.getElementsByClassName("addpost-category")[0].childNodes[1]
        ? document.getElementsByClassName("addpost-category")[0].childNodes[1]
        : "";

    if (category) {
      let currentIcon = document.getElementsByClassName("add-category-icon")[0];
      if (currentIcon) {
        currentIcon.remove();
      }
      let activeCat = options.filter((op) => op.value === category);

      if (activeCat && activeCat[0] && activeCat[0].image) {
        iconBox.innerHTML = `<img src="${activeCat[0].image.src}" style="width:16px;height:16px;margin-right:13px;margin-bottom:-2px;" class="list icon add-category-icon" />`;
      }
    }

    if (categoryDiv) {
      categoryDiv.prepend(iconBox.firstChild);
    }
  }, [category]);

  const _getLinkTypeData = () => {
    getLinkTypeData()
      .then((res) => {
        if (res.response.ok) {
          setLinkTypeOptions(_.values(res.json));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  React.useEffect(() => {
    _getLinkTypeData();
  }, []);

  const _addPostDataChange = (e, { value }) => {
    setErrors({ ...errors, [e.target.name]: "" });
    if (e.target.name === "email") {
      setEmail(value);
    }

    if (e.target.name === "title") {
      setTitle(value);
    }

    if (e.target.name === "linkType") {
      setLinkType(value);
    }

    if (e.target.name === "url") {
      setUrl(value);
    }

    setSubmitted(false);
  };

  const _setSelectedParentLinks = (links) => {
    setSelectedParentLinks(links);
  };

  const _setSelectedChildLinks = (links) => {
    setSelectedChildLinks(links);
  };

  const _categoryChange = (e, { value }) => {
    setCategory(value);
    setErrors({ ...errors, category: "" });
    setSubmitted(false);
  };

  const _setTags = (tagss) => {
    getTagIcon_(tagss[tagss.length - 1]);
    setTags(tagss);
  };
  const _tagsChange = (tagss) => {
    setErrors({ ...errors, tags: "" });
    getTagIcon_(tagss[tagss.length - 1]);
    setTags(tagss);
    setSubmitted(false);
  };

  const _showResponse = (res) => {
    setErrors({ ...errors, "g-recaptcha-response": "" });
    setRecaptchaResponse(res);
    setAddPostData({
      ...addPostData,
      recaptchaVerified: true,
      "g-recaptcha-response": res,
    });
  };

  const _expireRecaptcha = (res) => {
    setRecaptchaVerified(false);
    setRecaptchaResponse("");
  };

  const _submit = () => {
    const { isValid, errors } = validateUpdatePostInputs({
      id: props.id,
      email: localStorage.getItem("userEmail") || email,
      link_types: [...new Set([...selectedParentLinks, ...selectedChildLinks])],
      title: title,
      url: url,
      category: category,
      tags: [...tags],
    });
    if (!isValid) {
      setErrors(errors);
      return;
    } else {
      setErrors({ email: "", title: "", url: "", category: "", tags: "" });
    }
    setSubmit(true);
    updateSubmittedPost({
      id: props.id,
      email: localStorage.getItem("userEmail") || email,
      link_types: [...new Set([...selectedParentLinks, ...selectedChildLinks])],
      title: title,
      url: url,
      category: category,
      tags: [...tags],
    })
      .then((res) => {
        setSubmit(false);
        if (res.response.ok) {
          toast.dark(
            <b style={{ width: "400px" }}>Successfully submitted!</b>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
              hideProgressBar: true,
              style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
            }
          );
          setEmail("");
          setTitle("");
          setUrl("");
          setSelectedChildLinks([]);
          setSelectedParentLinks([]);
          setCategory("");
          setTags([]);
          if (props.onSubmitCallback) {
            props.onSubmitCallback();
          }
        } else {
          setErrors(res.json.errors);
        }
      })
      .then((rr) => {
        props.onClose();
      })
      .catch((err) => {
        toast.error(<b>{err}</b>, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 2000,
          hideProgressBar: true,
        });
      });
  };

  const _urlFocusOut = (e) => {
    checkUrlValidation(`?url=${e.target.value}`)
      .then((res) => {
        if (res.errors.length > 0 && res.errors.url) {
          setErrors({
            ...errors,
            url: res.errors.url,
          });
        } else {
          setErrors({
            ...errors,
            url: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const _recaptchaVerifyCallback = (res) => {
    if (res) {
      setErrors({ ...errors, "g-recaptcha-response": "" });
      setRecaptchaVerified(true);
      setRecaptchaResponse(res);
      // setAddPostData({
      //   ...addPostData,
      //   recaptchaVerified: true,
      //   "g-recaptcha-response": res,
      // })
    }
  };

  const _onLinkTypeChange = (e, parent_id, children) => {
    let parentId = parent_id;
    let childrenIds = [];
    _.values(children).forEach((element) => {
      return childrenIds.push(element.term_id);
    });
    let isParentAlreadyExists = selectedParentLinks.filter(
      (p) => p === parent_id
    );

    if (isEmpty(isParentAlreadyExists)) {
      setSelectedParentLinks([...selectedParentLinks, parentId]);
      setSelectedChildLinks([...selectedChildLinks, ...childrenIds]);
    } else {
      let updatedList = selectedParentLinks.filter((d, ind) => {
        return d === parent_id ? null : parent_id;
      });
      let unique = updatedList.filter((x) => x !== null);
      setSelectedParentLinks(unique);
      let uniqueChild = _.xor(selectedChildLinks, childrenIds);
      setSelectedChildLinks(uniqueChild);
    }
  };

  const _onChildrenLinkTypeChange = (e, child_id, parent_id, all_childrens) => {
    // let parentLink = linkTypeOptions.filter((data,index)=>{
    // 	return data.term_id === parent_id
    // })

    let isParentAlreadyExists = selectedParentLinks.filter(
      (p, ind) => p === parent_id
    );
    let isChildAlreadyExist = selectedChildLinks.filter(
      (ch, ind) => ch === child_id
    );

    if (isEmpty(isChildAlreadyExist)) {
      setSelectedChildLinks([...selectedChildLinks, child_id]);
      setSelectedParentLinks([...selectedParentLinks, parent_id]);
    } else {
      // if (!isEmpty(isParentAlreadyExists)) {
      // 	return;
      // } else {

      let count = 0;
      for (let i = 0; i < selectedChildLinks.length - 1; i++) {
        all_childrens.forEach((element, index) => {
          if (selectedChildLinks[i] === element.term_id) {
            count += 1;
          }
        });
      }
      if (count === 0) {
        let updatedParentList = selectedParentLinks.filter(
          (p, i) => p !== parent_id
        );
        setSelectedParentLinks(updatedParentList);
      }

      let updatedList = selectedChildLinks.filter((d, ind) => {
        return d === child_id ? null : child_id;
      });
      let unique = updatedList.filter((x) => x !== null);
      setSelectedChildLinks(unique);
      // }
    }
  };

  const _deleteLinkItem = (id) => {
    let isParent = selectedParentLinks.filter((par, index) => par === id);
    let isChild = selectedChildLinks.filter((ch, index) => ch === id);
    if (!isEmpty(isParent)) {
      // is a parent
      let updatedList = selectedParentLinks.filter((par, ind) => par !== id);
      setSelectedParentLinks(updatedList);
    } else if (!isEmpty(isChild)) {
      // is child
      let updatedList = selectedChildLinks.filter((ch, ind) => ch !== id);
      setSelectedChildLinks(updatedList);
    }
  };

  return (
    <div className="p-grid p-fluid dashboard page-contact">
      <MetaDecorator metaDataPerRoute={metaDecoratorData.add_post} />
      <div className="p-col-12 p-lg-12">
        <div className="card card-w-title" style={{ boxShadow: "none" }}>
          <div className="p-grid ">
            <div className="p-lg-12 add-post-page">
              <div className="p-col-12 p-md-12 textarea">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="title">Description</span>
                  <ErrorBody>{errors.title}</ErrorBody>
                </div>
                <div
                  className={`${
                    darkMode ? "textarea-input-dark" : "textarea-input"
                  }`}
                >
                  <i aria-hidden="true" class="pencil alternate big icon"></i>
                  <TextareaAutosize
                    className="t-area"
                    minRows={1}
                    maxRows={50}
                    value={title.length > 250 ? title.slice(0, 250) : title}
                    onChange={(e) => {
                      if (e.target.value.length < 251) {
                        setTitle(e.target.value);
                      }
                    }}
                    placeholder="Description"
                    style={{ backgroundColor: "red" }}
                  />
                  <span>{title ? title.length : "0"} / 250</span>
                </div>
              </div>
              {/* <ErrorBody>{errors.title}</ErrorBody> */}

              <div
                className={`p-col-12 p-md-12 ${
                  darkMode ? "url-input-dark" : "url-input-light"
                } `}
              >
                <Form.Input
                  disabled={props.loading}
                  fluid
                  icon="linkify"
                  iconPosition="left"
                  value={url}
                  label="URL"
                  placeholder="Formatted Link URL"
                  onChange={_addPostDataChange}
                  name="url"
                />
                <ErrorBody>{errors.url}</ErrorBody>
              </div>

              <div
                ref={dropRef}
                className="p-col-12 p-md-12"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span style={{ fontSize: "14px" }}>Link Type</span>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: props.settings.darkMode
                      ? "#cccccc"
                      : "white",
                    zIndex: 1,
                    opacity: props.loading ? 0.5 : 1,
                  }}
                >
                  <div style={{ maxWidth: "36px", minWidth: "35px" }}>
                    <Icon
                      name="staylinked"
                      className="tags-input-icon"
                      style={{
                        marginLeft: "10px",
                        color: isMenuOpen ? "rgba(0,0,0,0.8)" : "gray",
                        fontSize: "18px",
                      }}
                    />
                  </div>
                  <LinkTypeInput
                    setSelectedChildLinks={_setSelectedChildLinks}
                    setSelectedParentLinks={_setSelectedParentLinks}
                    selectedChildLinks={selectedChildLinks}
                    selectedParentLinks={selectedParentLinks}
                    onLinkTypeChange={_onLinkTypeChange}
                    isMenuOn={isMenuOpen}
                    setIsMenuOn={(ee) => {
                      if (props.loading) {
                        return;
                      }
                      setIsMenuOpen(ee);
                    }}
                    onChildrenLinkTypeChange={_onChildrenLinkTypeChange}
                    linkTypeOptions={linkTypeOptions}
                    deleteLinkItem={_deleteLinkItem}
                    darkMode={props.settings.darkMode}
                  />
                </div>
                <ErrorBody>{errors.link_types}</ErrorBody>
              </div>

              <div className="p-col-12 p-md-12">
                <Form.Select
                  disabled={props.loading}
                  selectOnBlur={false}
                  fluid
                  // selection
                  className={
                    props.settings.darkMode
                      ? "addpost-category addpost-category-dark-modal"
                      : "addpost-category addpost-category-modal"
                  }
                  label="Categories"
                  options={options}
                  placeholder="Choose a Category"
                  onChange={_categoryChange}
                  value={category ? category : ""}
                  name="category"
                />
                <ErrorBody>{errors.category}</ErrorBody>
              </div>
              <div
                ref={tagsRef}
                className="p-col-12 p-md-12"
                style={{ opacity: props.loading ? 0.5 : 1 }}
              >
                <label>Tags</label>
                <TagsInput
                  isAutoCompleteOn={isAutoCompleteOn}
                  setIsAutoCompleteOn={setIsAutoCompleteOn}
                  setFrom={setFrom}
                  from={from}
                  setTo={setTo}
                  to={to}
                  tagImageLoader={tagImageLoader}
                  lastTagImage={lastTagImage}
                  darkMode={props.settings.darkMode}
                  setTags={(e) => {
                    if (props.loading) {
                      return;
                    }
                    _setTags(e);
                  }}
                  value={tags}
                  onChange={(e) => {
                    if (props.loading) {
                      return;
                    }
                    _tagsChange(e);
                  }}
                />
                <ErrorBody>{errors.tags}</ErrorBody>
              </div>

              <div className="p-col-12 p-md-12">
                <Form.Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0px",
                    backgroundColor:
                      title.length < 1 ||
                      category.length < 1 ||
                      submit ||
                      !isInitialDataChanged
                        ? "gray"
                        : "black",
                  }}
                  disabled={
                    title.length < 1 ||
                    category.length < 1 ||
                    submit ||
                    tags.length < 1 ||
                    !isInitialDataChanged
                  }
                  primary
                  fluid
                  onClick={_submit}
                >
                  <BiAddToQueue
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  />
                  {submit ? <b>Submitting...</b> : <b>Submit</b>}
                </Form.Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  categories: state.categories,
});

export default withRouter(connect(mapStateToProps, {})(AddPostModal));
