import React, { useEffect, useState } from "react";
import { BiCookie } from "react-icons/bi";
import { FaCookieBite } from "react-icons/fa";
//from packages
import { Button } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import cookieImg from "../../assets/img/cookie.svg";
import CookieSettings from "./CookieSettings";
import { saveCookieConsent } from "../../api/api";
import { toast } from "react-toastify";

function AcceptCookies(props) {
  const [key, setKey] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const darkmode = useSelector((state) => state.settings.darkMode);

  const _onClose = () => {
    setOpen(false);
  };

  const notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkmode ? "#3C3C3C" : "#EDF0F5" },
    });

  useEffect(() => {
    setOpen(false);
  }, [props.location.pathname]);

  let cookieContent = store.getState().world.cookieContent;
  const _acceptCookies = (e) => {
    _saveSettings("acceptAll");
  };

  const _rejectCookies = (e) => {
    _saveSettings("rejectAll");
    // setKey(new Date());
  };

  const _saveSettings = (type) => {
    if (loading) {
      return;
    }

    const requestJson = {
      strictly_necessary_cookie: type === "acceptAll" ? true : false,
      performance_and_analytics_cookie: type === "acceptAll" ? true : false,
      advertisement_and_targeting_cookie: type === "acceptAll" ? true : false,
    };

    setLoading(true);
    saveCookieConsent(requestJson)
      .then((res) => {
        if (type === "acceptAll") {
          localStorage.setItem("disclaimerResponded", "accepted");
          props.setShowCookieBanner(false);
          setKey(new Date());
        } else {
          localStorage.setItem("disclaimerResponded", "rejected");
          props.setShowCookieBanner(false);
        }
        localStorage.setItem(
          "cookie-consent",
          JSON.stringify({
            necessary: requestJson.strictly_necessary_cookie || false,
            perfomance: requestJson.performance_and_analytics_cookie || false,
            adv: requestJson.advertisement_and_targeting_cookie || false,
          })
        );
        notify("Cookies successfully saved!");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  let showingAcceptCookiesBanner = true;

  if (localStorage.getItem("disclaimerResponded") && !props.showCookieBanner) {
    showingAcceptCookiesBanner = false;
  }

  if (!showingAcceptCookiesBanner) {
    return null;
  }
  let isMobile = window.innerWidth <= 700;
  if (!cookieContent) return null;

  return (
    <div
      className={`disclaimer-container ${darkmode ? "c-dark" : "c-light"} ${
        isMobile && `disclaimer-container--full`
      }`}
    >
      <div className="container">
        <div className="cookie-cont-wrapper">
          <div className="disclaimer-text">
            <div className="disclaimer-text__cookie-top">
              <b>We use cookies</b>
              <img src={cookieImg} />
            </div>
            <div className="disclaimer-text__cookie-bottom">
              <span style={{ lineHeight: "1px" }}>
                {/* {cookieContent ? parse(cookieContent) : ""} */}
                Hi, this website uses essential cookies to ensure its proper
                operation and
                <br /> tracking cookies to understand how you interact with it.
                The latter will be set only after consent.{" "}
                <label
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Let me choose
                </label>
              </span>
            </div>
            {/* <FaCookieBite />
					<br />
					<span style={{ lineHeight: "1px" }}>{cookieContent ? parse(cookieContent) : ""}</span>
					<br />
					<Button
						className="accept-button"
						size="tiny"
						positive
						style={{ backgroundColor: "black", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}
						onClick={_acceptCookies}
					>
						<span style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>Accept</span>
					</Button> */}
            {/* <div className="pp">
						<NavLink to="/privacy">
							<b style={{ borderBottomStyle: "solid", borderBottomWidth: "1px", borderColor: "#8a8a8a", paddingBottom: "0px" }}>Privacy Policy</b>
						</NavLink>{" "}
						<b>|</b>
						<NavLink to="/terms">
							{" "}
							<b style={{ borderBottomStyle: "solid", borderBottomWidth: "1px", borderColor: "#8a8a8a", paddingBottom: "0px" }}>Terms and Conditions</b>
						</NavLink>
					</div> */}
            {/* <p style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
						We use cookies to give you the best possible experience on our website. By clicking OK, you agree to our{" "}
						<NavLink to="/privacy-policy" style={{ paddingLeft: "3px" }}>
							Policy
						</NavLink>
						. If you would like to see terms and conditions, have a look
						<span className="link-special" style={{ paddingLeft: "3px" }}>
							<NavLink to="/terms-and-conditions">here</NavLink>
						</span>
						.
						<Button className="accept-button" size="tiny" positive style={{ backgroundColor: "black", textAlign: "center", display: "flex" }} onClick={_acceptCookies}>
							<span style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "3px" }}>Accept</span>
							<BiCookie style={{ fontSize: "18px", marginLeft: "5px" }} />
						</Button>
					</p> */}
          </div>
          <div className="disclaimer-actions">
            <button
              className="button-1"
              disabled={loading}
              style={{ opacity: loading ? 0.5 : 1 }}
              onClick={_acceptCookies}
            >
              Accept all
            </button>
            <button
              onClick={_rejectCookies}
              disabled={loading}
              className={darkmode ? "button-2-dark" : "button-2"}
              style={{ opacity: loading ? 0.5 : 1 }}
            >
              Reject all
            </button>
          </div>
        </div>
      </div>
      <CookieSettings
        onClose={_onClose}
        open={open}
        showCookieBanner={props.showCookieBanner}
        setShowCookieBanner={props.setShowCookieBanner}
      />
    </div>
  );
}

export default withRouter(AcceptCookies);
