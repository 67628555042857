const GLOBALS = {
  APP_NAME: "PikiLinks",
  BLANK_LINK: "#",
  SERVER_URL: "https://dev.app.pikilinks.com/"
    // process.env.REACT_APP_MODE === "prod" ||
    // process.env.NODE_ENV === "production"
    //   ? `https://app.pikilinks.com/`
    //   : "https://dev.app.pikilinks.com/",
};
export default GLOBALS;
